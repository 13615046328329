import React, { useRef, useState, useContext } from "react";

//context
import { AuthContext } from "../contexts/AuthContext";

//data display
import AlertModal from "../data_display/AlertModal";

//services
import { CustomersService } from "../services/CustomersService";

export default function AlertEditCustomer ({currentUser, onSave, onCancel}) {
    const authContext = useContext(AuthContext); 

    const formValues = {
        idusuario:useRef(),
        nombres:useRef(),
        apellidos:useRef(),
        identificacion:useRef(),
        telefono:useRef(),
        email:useRef(),
    };

    const [showAlert, setShowAlert] = useState(false);
    const alertMessage = useRef("");

    function onClickSave(e) {
        e.preventDefault();
        let isValidForm = true;
        if(formValues.nombres.current.value < 3)
        {
            alertMessage.current = "Es necesario ingresar un nombre correcto!";
            isValidForm = false;
        }else if(formValues.apellidos.current.value < 3)
        {
            alertMessage.current = "Es necesario ingresar un apellidos correcto!";
            isValidForm = false;
        }else if(formValues.identificacion.current.value < 3)
        {
            alertMessage.current = "Es necesario ingresar un identificacion correcto!";
            isValidForm = false;
        }else if(formValues.telefono.current.value < 3)
        {
            alertMessage.current = "Es necesario ingresar un telefono correcto!";
            isValidForm = false;
        }else if(formValues.email.current.value < 3)
        {
            alertMessage.current = "Es necesario ingresar un email correcto!";
            isValidForm = false;
        }

        if(isValidForm)
        {
            formValues.idusuario.current = currentUser.idusuario_cliente;
            CustomersService.EditCustomerService(authContext.token, formValues, CallbackUserSave)
        }else {
            setShowAlert(true);
        }
    };

    function CallbackUserSave(tmpStatus, tmpMessage, tmpToken) {
        switch(tmpStatus) {
            case 1: //Success                                
                authContext.token = tmpToken;
                onSave();
                break;
            default: //Validation error
                alertMessage.current = ""+tmpMessage;
                setShowAlert(true);
                break;
        }   
    }

    return(
        <>        
        {showAlert && <AlertModal message={alertMessage.current} onAccept={()=>{setShowAlert(false);}} />}
        <div style={{width:"100vw",height:"100vh", position:"absolute", top:"0px", 
        right:"0px", zIndex:"2", backgroundColor:"var(--color-dark-50)", display:"flex"}}>            
            <div style={{width:"80%", maxWidth:"500px", margin:"auto", backgroundColor:"var(--color-light-100)",
                flexFlow:"column", padding:"20px", display:"flex",
                alignItems:"stretch", justifyContent:"center"}}>
                <p className="title" style={{textAlign:"center"}}>Editar Usuario</p>
                <input ref={formValues.nombres} defaultValue={currentUser.nombres} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="text" placeholder="Nombres" />  
                <input ref={formValues.apellidos} defaultValue={currentUser.apellidos} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="text" placeholder="Apellidos" />  
                <input ref={formValues.identificacion} defaultValue={currentUser.identificacion} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="text" placeholder="Identificación" />
                <input ref={formValues.telefono} defaultValue={currentUser.telefono} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="text" placeholder="Teléfono" />
                <input ref={formValues.email} defaultValue={currentUser.email} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="email" placeholder="E-mail" />  
                
                <div style={{width:"100%", display:"flex"}}>
                    <button className="light" style={{flex:"0.5 0 auto", margin:"10px 20px 10px 20px" }} 
                    onClick={()=> onCancel()}>Cancelar</button>
                    <button className="dark" style={{flex:"0.5 0 auto", margin:"10px 20px 10px 20px" }} 
                    onClick={(e)=> onClickSave(e)}>Guardar</button>
                </div>       
            </div>
        </div>
        </>
    );
}