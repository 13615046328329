export const UtilitiesService = {
    
    GetAllWeekDiscountService(token, callback) {        
        fetch('https://api.colombialivinghotels.com/week_discount_all', {
        //fetch('/week_discount_all', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({token:token})
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("GetAllWeekDiscountService Message:",data.Message);
            callback(parseInt(data.Status) ,data.Message, data.WeekDiscount, data.Token);
        });
    },

    EditWeekDiscountService(token, tmpWeek, callback) {               
        fetch('https://api.colombialivinghotels.com/week_discount_edit', {
        //fetch('/week_discount_edit', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({
                token:token,
                idweek_discount: tmpWeek.idweek_discount,
                weeks:tmpWeek.weeks,
                discount:tmpWeek.discount,
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("EditWeekDiscountService Message:",data.Message);            
            callback(parseInt(data.Status) ,data.Message, data.Token);
        });
    }
}