import React, { useEffect, useState, useRef, useContext } from "react";

//context
import { AuthContext } from "../contexts/AuthContext";

//layouts
import PageContent from "../layouts/PageContent";
import FilterTopBarContainer from "../layouts/FilterTopBarContainer";

//services
import { CustomersService } from "../services/CustomersService";

//data display
import AlertModal from "../data_display/AlertModal";
import AlertEditCustomer from "../data_display/AlertEditCustomer";

const colorEnabled = "#8fce00";
const colorDisabled = "#b7271d";
const perPage = 20;

export default function CustomersPage() {
    const authContext = useContext(AuthContext);
    const [userList, setUserList] = useState([]);

    const [showAlert, setShowAlert] = useState(false);
    const alertMessage = useRef("");

    const [showAlertCreateUser, setShowAlertCreateUser] = useState(false);
    const [showAlertEditUser, setShowAlertEditUser] = useState(false);

    const currentUser = useRef();
    const currentPage = useRef(1);
    const totalPages = useRef(1);
    const orderColumn = useRef('nombres');
    const orderType = useRef('DESC');

    useEffect(()=>{
        UpdateUSerList();
    }, []);

    function UpdateUSerList (){
        CustomersService.GetAllCustomersService(authContext.token, currentPage.current, perPage, orderColumn.current, orderType.current, CallbackUserList)
    }
    function CallbackUserList (tmpStatus, tmpMessage, tmpUsers, tmpTotalPages, tmpToken)
    {
        switch(tmpStatus) {
            case 1: //Success         
                totalPages.current = tmpTotalPages;       
                setUserList(tmpUsers);
                authContext.token = tmpToken;
                break;
            default: //Validation error
                alertMessage.current = ""+tmpMessage;
                setShowAlert(true);
                break;
        }   
    }

    function OnClickMovePage(tmpisNext)
    {
        if(tmpisNext && (currentPage.current < totalPages.current))
        {
            currentPage.current += 1;
            UpdateUSerList();
        }else if(currentPage.current > 1)
        {
            currentPage.current -= 1;
            UpdateUSerList();
        }
    }

    return(
        <PageContent hasFilterBar="true" >
            {showAlert && <AlertModal message={alertMessage.current} onAccept={()=>{setShowAlert(false);}} />}            
            {showAlertEditUser &&<AlertEditCustomer currentUser={currentUser.current} onSave={()=>{UpdateUSerList(); setShowAlertEditUser(false);}} onCancel={()=>setShowAlertEditUser(false)}/>}
            <FilterTopBarContainer/>
            <div style={{width:"calc(100% - 20px)", backgroundColor:"var(--color-light-100)", 
                padding:"20px 10px 20px 10px", boxShadow:"0px 0px 5px 0px var(--color-dark-10)",
                overflow:"auto"}}>
                <div style={{display:"flex"}}>
                    <p style={{margin:"0px 10px 40px 0px", fontWeight:"300"}}>
                        <b>Lista de clientes</b>
                    </p>
                </div>
                <table style={{minWidth:"100%"}}>
                    <thead>
                        <tr>
                            <th style={{cursor:"pointer"}} 
                            onClick={()=>{orderColumn.current = 'nombres'; 
                            orderType.current = orderType.current === 'ASC' ? 'DESC':'ASC';
                            UpdateUSerList();}}>
                                Nombres
                                <svg style={{margin:"0px 0px 0px 10px", rotate:orderType.current === 'ASC' ? '0deg':'180deg'}} width="10px" height="10px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                                    <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 7l6 6 6-6"/>
                                </svg>
                            </th>
                            <th>Apellidos</th>
                            <th>Identificación</th>
                            <th>Teléfono</th>
                            <th>Email</th>
                            <th style={{display:"flex"}}>
                                Actions
                                <svg style={{margin:"8px 0px 0px 10px"}} width="10px" height="10px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                                    <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 13l-6-6-6 6"/>
                                </svg>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        userList.map((item)=>{
                        return <tr key={item.idusuario_cliente}>
                            <td>{item.nombres}</td>
                            <td>{item.apellidos}</td>
                            <td>{item.identificacion}</td>
                            <td>{item.telefono}</td>
                            <td>{item.email}</td>
                            <td> 
                                <div style={{display:"flex", justifyContent:"center"}}>
                                    <div style={{width:"50px", cursor:"pointer"}}
                                        onClick={()=>{currentUser.current = item; setShowAlertEditUser(true);}}>
                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path fill="#000000" fillRule="evenodd" d="M15.747 2.97a.864.864 0 011.177 1.265l-7.904 7.37-1.516.194.653-1.785 7.59-7.044zm2.639-1.366a2.864 2.864 0 00-4-.1L6.62 8.71a1 1 0 00-.26.39l-1.3 3.556a1 1 0 001.067 1.335l3.467-.445a1 1 0 00.555-.26l8.139-7.59a2.864 2.864 0 00.098-4.093zM3.1 3.007c0-.001 0-.003.002-.005A.013.013 0 013.106 3H8a1 1 0 100-2H3.108a2.009 2.009 0 00-2 2.19C1.256 4.814 1.5 7.848 1.5 10c0 2.153-.245 5.187-.391 6.81A2.009 2.009 0 003.108 19H17c1.103 0 2-.892 2-1.999V12a1 1 0 10-2 0v5H3.106l-.003-.002a.012.012 0 01-.002-.005v-.004c.146-1.62.399-4.735.399-6.989 0-2.254-.253-5.37-.4-6.99v-.003zM17 17c-.001 0 0 0 0 0zm0 0z"/>
                                        </svg>
                                    </div>                            
                                </div>
                            </td>
                        </tr>
                        })
                        }
                    </tbody>
                </table>
            </div>
            <div style={{margin:"20px", height:"50px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div style={{rotate:"180deg", display: currentPage.current > 1 ? "flex":"none", cursor:"pointer"}}
                onClick={()=>OnClickMovePage(false)}>
                    <svg width="24" height="24" style={{fill:"var(--color-font-medium)"}}>
                        <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/>
                    </svg>
                </div>
                <div style={{display:"flex", margin:"0px 10px 0px 10px", color:"var(--color-font-medium)"}}>                    
                    {currentPage.current} de {totalPages.current}
                </div>
                <div style={{display: currentPage.current < totalPages.current ? "flex":"none", cursor:"pointer"}}
                onClick={()=>OnClickMovePage(true)}>
                    <svg width="24" height="24" style={{fill:"var(--color-font-medium)"}}>
                        <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/>
                    </svg>
                </div>
            </div>
        </PageContent>
    );
}