import React from "react";

export default function PageContent({children, hasFilterBar}) {
    const marginTopSt = hasFilterBar ? "50px":"0px";
    return(
        <section style={{width:"calc(100% - 220px)", display:"block", marginTop:marginTopSt, padding:"10px",
        backgroundColor:"var(--color-dark-5)", marginLeft:"200px"}}>
            {children}
        </section>
    );
}