import React, { useState, useRef, useEffect } from 'react';
import styles from  '../css/SearchInput.module.css';

const list = [
  { id: 1, name: 'Tom', type: 'Cat' },
  { id: 2, name: 'Zeus', type: 'Labrador' },
  { id: 3, name: 'Jax', type: 'Malamute' },
  { id: 4, name: 'Seb', type: 'Developer' },
  { id: 5, name: 'MacBook', type: 'Notebook' },
];

export default function SearchInput({list, onSelection}) {

  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const dropdownRef = useRef(null);

  // click away listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);

  const handleClick = e => {
    if (dropdownRef.current.contains(e.target)) {
      return;
    }
    setVisible(false);
  };

  const handleChange = e => {
    console.log("----CAMBIO: "+e.target.value);
    if(e.target.value == 0)
      onSelection(undefined);
    setSearchValue(e.target.value);
    if (!visible) {
      setVisible(true);
    }
  };

  const selectItem = item => {
    setSearchValue(item.nombres + " " + item.apellidos + " " +item.identificacion);
    setSelectedItem(item.identificacion);
    setVisible(false);
    onSelection(item);
  };

  const selectChange = e => {
    console.log(e.target.value);
  };

  const searchFilter = (searchValue, list, searchBy = 'nombres') => {
    let lowerCaseQuery = searchValue.toLowerCase();
    let filteredList = searchValue
      ? list.filter(x => x[searchBy].toLowerCase().includes(lowerCaseQuery))
      : list;
    return filteredList;
  };


  return (
    <div className={styles.container_searchinput}>
      <div tabIndex="0" className={styles.input_container}>
        <input
          className={styles.input}
          type="text"
          placeholder="Search Text"
          value={searchValue}
          onChange={handleChange}
          onFocus={() => {
            // if (searchValue) {
            setVisible(true);
            // };
          }}
        />
      </div>
      <div ref={dropdownRef} className={visible ? styles.dropdownSearch_visible:styles.dropdownSearch}>
        {visible && (
          <ul>
            {!list && (
              <li key="zxc" className={styles.dropdown_item}>
                no result
              </li>
            )}
            {/* you can remove the searchFilter if you get results from Filtered API like Google search */}
            {list &&
              searchFilter(searchValue, list).map(x => (
                <li
                  key={x.idusuario_backoffice}
                  onClick={() => selectItem(x)}
                  className={styles.dropdown_item}
                >
                  <div className={styles.item_text1}>{x.nombres} {x.apellidos}</div>
                  <div className={styles.item_text2}>{x.identificacion}</div>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
}