import React, { useEffect, useRef, useState } from "react"

//context
import { AuthContext } from "../contexts/AuthContext";

//Services
import { LoginService } from "../services/AuthService";

export default function AuthProvider({children})
{  
  const [auth, setAuth] = useState({
    user: null /*{ 
      "apellidos": null,
      "email": null,
      "identificacion": null,
      "idusuario_backoffice": null,
      "nombres": null,
      "rol_idrol": null,
      "telefono": null
    }*/,
    token: null,
    signin:signin,
    signout:signout
  });

  function signin (formUser, callback) {
    LoginService.signin(formUser, (tmpStatus, tmpMessage, tmpUser, tmpToken)=> {      
      if(tmpStatus === 1) {
        setAuth({...auth, user:tmpUser, token:tmpToken});      
        sessionStorage.setItem('token', tmpToken);
      }
      
      if(callback)
        callback(tmpStatus, tmpMessage, tmpUser);      
    });
  };

  function signout (callback) {
    return callback.signout(() => {
      callback();
    });
  };  

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}