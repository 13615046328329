import React, { useEffect, useState, useRef, useContext } from "react";

//context
import { AuthContext } from "../contexts/AuthContext";

//layouts
import PageContent from "../layouts/PageContent";

//services
import { UtilitiesService } from "../services/UtilitiesService";

//data display
import AlertModal from "../data_display/AlertModal";

export default function WeekDiscountPage () {

    const authContext = useContext(AuthContext);
    const [weekDiscountList, setWeekDiscountList] = useState([]);

    const [showAlert, setShowAlert] = useState(false);
    const alertMessage = useRef("");

    const formValues = {        
        semanas1:useRef(),
        descuentos1:useRef(),        
        semanas2:useRef(),
        descuentos2:useRef(),
        semanas3:useRef(),
        descuentos3:useRef(),
        semanas4:useRef(),
        descuentos4:useRef(),
    };

    const [editLsit, SetEditList] = useState({
        edit1:false,
        edit2:false,
        edit3:false,
        edit4:false,
    })

    useEffect(()=>{
        UpdateWeekDiscountList();
    }, []);

    function UpdateWeekDiscountList (){
        UtilitiesService.GetAllWeekDiscountService(authContext.token, CallbackWeekDiscount)
    }

    function CallbackWeekDiscount (tmpStatus, tmpMessage, tmpWeekDiscount, tmpToken)
    {
        switch(tmpStatus) {
            case 1: //Success         
                console.log("------------cargoooo",tmpWeekDiscount);
                formValues.semanas1.current.value = tmpWeekDiscount[0].weeks;
                formValues.descuentos1.current.value = tmpWeekDiscount[0].discount;
                formValues.semanas2.current.value = tmpWeekDiscount[1].weeks;
                formValues.descuentos2.current.value = tmpWeekDiscount[1].discount;
                formValues.semanas3.current.value = tmpWeekDiscount[2].weeks;
                formValues.descuentos3.current.value = tmpWeekDiscount[2].discount;
                formValues.semanas4.current.value = tmpWeekDiscount[3].weeks;
                formValues.descuentos4.current.value = tmpWeekDiscount[3].discount;
                setWeekDiscountList(tmpWeekDiscount);                
                authContext.token = tmpToken;
                break;
            default: //Validation error
                alertMessage.current = ""+tmpMessage;
                setShowAlert(true);
                break;
        }   

    }

    function OnSave(tmpFormIndex)
    {
        if(isNaN(formValues.semanas1.current.value) || formValues.semanas1.current.value <= 0 || formValues.semanas1.current.value > 34 ||
        isNaN(formValues.semanas2.current.value) || formValues.semanas2.current.value <= 0 || formValues.semanas2.current.value > 34 ||
        isNaN(formValues.semanas3.current.value) || formValues.semanas3.current.value <= 0 || formValues.semanas3.current.value > 34 ||
        isNaN(formValues.semanas4.current.value) || formValues.semanas4.current.value <= 0 || formValues.semanas4.current.value > 34)
        {
            alertMessage.current = "La Semana debe ser número del 1 hasta 34";
            setShowAlert(true);
            return;
        }else if(isNaN(formValues.descuentos1.current.value) || formValues.descuentos1.current.value < 0 || formValues.descuentos1.current.value > 1 ||
        isNaN(formValues.descuentos2.current.value) || formValues.descuentos2.current.value < 0 || formValues.descuentos2.current.value > 1 ||
        isNaN(formValues.descuentos3.current.value) || formValues.descuentos3.current.value < 0 || formValues.descuentos3.current.value > 1 ||
        isNaN(formValues.descuentos4.current.value) || formValues.descuentos4.current.value < 0 || formValues.descuentos4.current.value > 1){
            alertMessage.current = "El Descuento debe ser número del 0 hasta 1";
            setShowAlert(true);
            return;
        }


        let tmpData = {
            idweek_discount:null,
            weeks:null,
            discount:null
        }

        switch(tmpFormIndex){
            case 1:
                tmpData.idweek_discount = weekDiscountList[0].idweek_discount;
                tmpData.weeks = formValues.semanas1.current.value;
                tmpData.discount = formValues.descuentos1.current.value;
                SetEditList({...editLsit, edit1:!editLsit.edit1, edit2:false, edit3:false, edit4:false});
                break;
            case 2:
                tmpData.idweek_discount = weekDiscountList[1].idweek_discount;
                tmpData.weeks = formValues.semanas2.current.value;
                tmpData.discount = formValues.descuentos2.current.value;
                SetEditList({...editLsit, edit2:!editLsit.edit2, edit1:false, edit3:false, edit4:false});
                break;
            case 3:
                tmpData.idweek_discount = weekDiscountList[2].idweek_discount;
                tmpData.weeks = formValues.semanas3.current.value;
                tmpData.discount = formValues.descuentos3.current.value;
                SetEditList({...editLsit, edit3:!editLsit.edit3, edit2:false, edit1:false, edit4:false});
                break;
            case 4:
                tmpData.idweek_discount = weekDiscountList[3].idweek_discount;
                tmpData.weeks = formValues.semanas4.current.value;
                tmpData.discount = formValues.descuentos4.current.value;
                SetEditList({...editLsit, edit4:!editLsit.edit4, edit2:false, edit3:false, edit1:false});
                break;
        }

        UtilitiesService.EditWeekDiscountService(authContext.token, tmpData, CallbackWeekDiscountSave)
    }

    function CallbackWeekDiscountSave(tmpStatus, tmpMessage, tmpToken) {
        switch(tmpStatus) {
            case 1: //Success                                
                authContext.token = tmpToken;
                alertMessage.current = ""+tmpMessage;
                setShowAlert(true);
                break;
            default: //Validation error
                alertMessage.current = ""+tmpMessage;
                setShowAlert(true);
                break;
        }
    }

    return(
        <PageContent hasFilterBar="true" >
            {showAlert && <AlertModal message={alertMessage.current} onAccept={()=>{setShowAlert(false);}} />}
            <div style={{width:"calc(100% - 20px)", backgroundColor:"var(--color-light-100)", 
                padding:"20px 10px 20px 10px", boxShadow:"0px 0px 5px 0px var(--color-dark-10)",
                overflow:"auto"}}>
                <div style={{display:"flex"}}>
                    <p style={{margin:"0px 10px 40px 0px", fontWeight:"300"}}>
                        <b>% Descuento por semana</b>
                    </p>
                </div>
                <table style={{minWidth:"100%"}}>
                    <thead>
                        <tr>
                            <th>Desde la compra de + de semana/s:</th>
                            <th>Descuento</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input ref={formValues.semanas1} disabled={!editLsit.edit1} 
                                style={{ flex:"1 0 auto", margin:"10px" }} type="text" placeholder="Ingrese semana" /> 
                            </td>
                            <td>
                                <input ref={formValues.descuentos1} disabled={!editLsit.edit1}
                                style={{ flex:"1 0 auto", margin:"10px" }} type="text" placeholder="Ingrese descuento" /> 
                            </td>
                            <td> 
                                <div style={{display:"flex", justifyContent:"left"}}>
                                    <div style={{width:"50px", cursor:"pointer"}} 
                                    onClick={()=>{SetEditList({...editLsit, edit1:!editLsit.edit1, edit2:false, edit3:false, edit4:false})}}>
                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path fill="#000000" fillRule="evenodd" d="M15.747 2.97a.864.864 0 011.177 1.265l-7.904 7.37-1.516.194.653-1.785 7.59-7.044zm2.639-1.366a2.864 2.864 0 00-4-.1L6.62 8.71a1 1 0 00-.26.39l-1.3 3.556a1 1 0 001.067 1.335l3.467-.445a1 1 0 00.555-.26l8.139-7.59a2.864 2.864 0 00.098-4.093zM3.1 3.007c0-.001 0-.003.002-.005A.013.013 0 013.106 3H8a1 1 0 100-2H3.108a2.009 2.009 0 00-2 2.19C1.256 4.814 1.5 7.848 1.5 10c0 2.153-.245 5.187-.391 6.81A2.009 2.009 0 003.108 19H17c1.103 0 2-.892 2-1.999V12a1 1 0 10-2 0v5H3.106l-.003-.002a.012.012 0 01-.002-.005v-.004c.146-1.62.399-4.735.399-6.989 0-2.254-.253-5.37-.4-6.99v-.003zM17 17c-.001 0 0 0 0 0zm0 0z"/>
                                        </svg>
                                    </div>

                                    <div style={{width:"50px", cursor:"pointer"}} hidden={!editLsit.edit1}
                                    onClick={()=>OnSave(1)}>                            
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#000000" fillRule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"/>
                                        </svg>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <input ref={formValues.semanas2} disabled={!editLsit.edit2}
                                style={{ flex:"1 0 auto", margin:"10px" }} type="text" placeholder="Ingrese semana" /> 
                            </td>
                            <td>
                                <input ref={formValues.descuentos2} disabled={!editLsit.edit2}
                                style={{ flex:"1 0 auto", margin:"10px" }} type="text" placeholder="Ingrese descuento" /> 
                            </td>
                            <td> 
                                <div style={{display:"flex", justifyContent:"left"}}
                                onClick={()=>{SetEditList({...editLsit, edit2:!editLsit.edit2, edit1:false, edit3:false, edit4:false})}}>
                                    <div style={{width:"50px", cursor:"pointer"}}>
                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path fill="#000000" fillRule="evenodd" d="M15.747 2.97a.864.864 0 011.177 1.265l-7.904 7.37-1.516.194.653-1.785 7.59-7.044zm2.639-1.366a2.864 2.864 0 00-4-.1L6.62 8.71a1 1 0 00-.26.39l-1.3 3.556a1 1 0 001.067 1.335l3.467-.445a1 1 0 00.555-.26l8.139-7.59a2.864 2.864 0 00.098-4.093zM3.1 3.007c0-.001 0-.003.002-.005A.013.013 0 013.106 3H8a1 1 0 100-2H3.108a2.009 2.009 0 00-2 2.19C1.256 4.814 1.5 7.848 1.5 10c0 2.153-.245 5.187-.391 6.81A2.009 2.009 0 003.108 19H17c1.103 0 2-.892 2-1.999V12a1 1 0 10-2 0v5H3.106l-.003-.002a.012.012 0 01-.002-.005v-.004c.146-1.62.399-4.735.399-6.989 0-2.254-.253-5.37-.4-6.99v-.003zM17 17c-.001 0 0 0 0 0zm0 0z"/>
                                        </svg>
                                    </div>

                                    <div style={{width:"50px", cursor:"pointer"}} hidden={!editLsit.edit2}
                                    onClick={()=>OnSave(2)}>                            
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#000000" fillRule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"/>
                                        </svg>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <input ref={formValues.semanas3} disabled={!editLsit.edit3}
                                style={{ flex:"1 0 auto", margin:"10px" }} type="text" placeholder="Ingrese semana" /> 
                            </td>
                            <td>
                                <input ref={formValues.descuentos3} disabled={!editLsit.edit3}
                                style={{ flex:"1 0 auto", margin:"10px" }} type="text" placeholder="Ingrese descuento" /> 
                            </td>
                            <td> 
                                <div style={{display:"flex", justifyContent:"left"}}
                                onClick={()=>{SetEditList({...editLsit, edit3:!editLsit.edit3, edit2:false, edit1:false, edit4:false})}}>
                                    <div style={{width:"50px", cursor:"pointer"}}>
                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path fill="#000000" fillRule="evenodd" d="M15.747 2.97a.864.864 0 011.177 1.265l-7.904 7.37-1.516.194.653-1.785 7.59-7.044zm2.639-1.366a2.864 2.864 0 00-4-.1L6.62 8.71a1 1 0 00-.26.39l-1.3 3.556a1 1 0 001.067 1.335l3.467-.445a1 1 0 00.555-.26l8.139-7.59a2.864 2.864 0 00.098-4.093zM3.1 3.007c0-.001 0-.003.002-.005A.013.013 0 013.106 3H8a1 1 0 100-2H3.108a2.009 2.009 0 00-2 2.19C1.256 4.814 1.5 7.848 1.5 10c0 2.153-.245 5.187-.391 6.81A2.009 2.009 0 003.108 19H17c1.103 0 2-.892 2-1.999V12a1 1 0 10-2 0v5H3.106l-.003-.002a.012.012 0 01-.002-.005v-.004c.146-1.62.399-4.735.399-6.989 0-2.254-.253-5.37-.4-6.99v-.003zM17 17c-.001 0 0 0 0 0zm0 0z"/>
                                        </svg>
                                    </div>

                                    <div style={{width:"50px", cursor:"pointer"}} hidden={!editLsit.edit3}
                                    onClick={()=>OnSave(3)}>                            
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#000000" fillRule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"/>
                                        </svg>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        
                        <tr>
                            <td>
                                <input ref={formValues.semanas4} disabled={!editLsit.edit4}
                                style={{ flex:"1 0 auto", margin:"10px" }} type="text" placeholder="Ingrese semana" /> 
                            </td>
                            <td>
                                <input ref={formValues.descuentos4} disabled={!editLsit.edit4}
                                style={{ flex:"1 0 auto", margin:"10px" }} type="text" placeholder="Ingrese descuento" /> 
                            </td>
                            <td> 
                                <div style={{display:"flex", justifyContent:"left"}}
                                onClick={()=>{SetEditList({...editLsit, edit4:!editLsit.edit4, edit2:false, edit3:false, edit1:false})}}>
                                    <div style={{width:"50px", cursor:"pointer"}}>
                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path fill="#000000" fillRule="evenodd" d="M15.747 2.97a.864.864 0 011.177 1.265l-7.904 7.37-1.516.194.653-1.785 7.59-7.044zm2.639-1.366a2.864 2.864 0 00-4-.1L6.62 8.71a1 1 0 00-.26.39l-1.3 3.556a1 1 0 001.067 1.335l3.467-.445a1 1 0 00.555-.26l8.139-7.59a2.864 2.864 0 00.098-4.093zM3.1 3.007c0-.001 0-.003.002-.005A.013.013 0 013.106 3H8a1 1 0 100-2H3.108a2.009 2.009 0 00-2 2.19C1.256 4.814 1.5 7.848 1.5 10c0 2.153-.245 5.187-.391 6.81A2.009 2.009 0 003.108 19H17c1.103 0 2-.892 2-1.999V12a1 1 0 10-2 0v5H3.106l-.003-.002a.012.012 0 01-.002-.005v-.004c.146-1.62.399-4.735.399-6.989 0-2.254-.253-5.37-.4-6.99v-.003zM17 17c-.001 0 0 0 0 0zm0 0z"/>
                                        </svg>
                                    </div>

                                    <div style={{width:"50px", cursor:"pointer"}} hidden={!editLsit.edit4}
                                    onClick={()=>OnSave(4)}>                            
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#000000" fillRule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"/>
                                        </svg>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </PageContent>
    );
}