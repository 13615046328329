import React, { useRef, useState, useContext } from "react";

//context
import { AuthContext } from "../contexts/AuthContext";

//data display
import AlertModal from "../data_display/AlertModal";

//services
import {PropertySalesService} from "../services/PropertySalesService";

export default function AlertEditPropertySale ({currentItem, onSave, onCancel}) {
    const authContext = useContext(AuthContext); 

    const formValues = {
        compra_idcompra:useRef(),
        weeks_idweek:useRef(),
        floor_percentage_idfloor_percentage:useRef(),
        apartamento_idapartamento:useRef(),
        estado:useRef(), //0=disponible,1=reservado,2=bloqueado,3=vendido, 4=cancelada
    };

    const [showAlert, setShowAlert] = useState(false);
    const alertMessage = useRef("");

    function onClickSave(e) {
        e.preventDefault();

        formValues.compra_idcompra.current = currentItem.idcompra;
        formValues.weeks_idweek.current = currentItem.semana;
        formValues.floor_percentage_idfloor_percentage.current = currentItem.piso;
        formValues.apartamento_idapartamento.current = currentItem.apartamento;
        PropertySalesService.EditPropertySaleService(authContext.token, formValues, CallbackSave)
    };

    function CallbackSave(tmpStatus, tmpMessage, tmpToken) {
        switch(tmpStatus) {
            case 1: //Success                                
                authContext.token = tmpToken;
                onSave();
                break;
            default: //Validation error
                alertMessage.current = ""+tmpMessage;
                setShowAlert(true);
                break;
        }   
    }

    return(
        <>        
        {showAlert && <AlertModal message={alertMessage.current} onAccept={()=>{setShowAlert(false);}} />}
        <div style={{width:"100vw",height:"100vh", position:"absolute", top:"0px", 
        right:"0px", zIndex:"2", backgroundColor:"var(--color-dark-50)", display:"flex"}}>            
            <div style={{width:"80%", maxWidth:"500px", margin:"auto", backgroundColor:"var(--color-light-100)",
                flexFlow:"column", padding:"20px", display:"flex",
                alignItems:"stretch", justifyContent:"center"}}>
                <p className="title" style={{textAlign:"center"}}>Editar Venta</p>
                <label style={{ flex:"1 0 auto", margin:"0px 10px 0px 10px"}} >Piso</label>
                <input defaultValue={currentItem.piso} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="text" disabled/>  
                <label style={{ flex:"1 0 auto", margin:"0px 10px 0px 10px"}} >Tipo</label>
                <input defaultValue={currentItem.tipo} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="text"  disabled/>  
                <label style={{ flex:"1 0 auto", margin:"0px 10px 0px 10px"}} >Apartamento</label>
                <input defaultValue={currentItem.apartamento} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="text" disabled/>
                <label style={{ flex:"1 0 auto", margin:"0px 10px 0px 10px"}} >Semana</label>
                <input defaultValue={currentItem.semana} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="text" disabled/>
                <label style={{ flex:"1 0 auto", margin:"0px 10px 0px 10px"}} >Estado</label>
                {/* 0=disponible,1=reservado,2=bloqueado,3=vendido, 4=cancelada */}                
                <select ref={formValues.estado} defaultValue={currentItem.estado} placeholder="Estado" style={{ flex:"1 0 auto", margin:"10px" }}>
                    <option value="1">Reservada</option>
                    <option value="3">Vendida</option>
                    <option value="4">Cancelada</option>
                </select>
                <div style={{width:"100%", display:"flex"}}>
                    <button className="light" style={{flex:"0.5 0 auto", margin:"10px 20px 10px 20px" }} 
                    onClick={()=> onCancel()}>Cancelar</button>
                    <button className="dark" style={{flex:"0.5 0 auto", margin:"10px 20px 10px 20px" }} 
                    onClick={(e)=> onClickSave(e)}>Guardar</button>
                </div>       
            </div>
        </div>
        </>
    );
}