import React from "react";

export default function FilterTopBarContainer({children}) {
    return(
        <div style={{position:"fixed", left:"200px", top:"0px", display:"flex", padding:"5px",
        height:"40px", width:"100%", backgroundColor:"var(--color-dark-5)",
        boxShadow:"0px 1px 5px 0px var(--color-dark-20)"}}>
            {children}
        </div>
    );
}