import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

//context
import { AuthContext } from "../contexts/AuthContext";

//layouts
import MenuLeftContainer from "../layouts/MenuLeftContainer";

//inputs
import MenuLeftButton from "../inputs/MenuLeftButton";

export default function MenuLeft ()
{
    const authContext = useContext(AuthContext);
    const navigation = useNavigate();
    const [indexMenu,SetIndexMenu] = useState(3);
    
    function OnClickButtonMenu(tmpIndexMenu)
    {
        switch(tmpIndexMenu)
        {
            case 0: 
            SetIndexMenu(tmpIndexMenu);
            navigation("home");
            break;

            case 1: 
            SetIndexMenu(tmpIndexMenu);
            navigation("user");
            break;

            case 2: 
            SetIndexMenu(tmpIndexMenu);
            navigation("properties");
            break;

            case 3: 
            SetIndexMenu(tmpIndexMenu);
            navigation("property_sales");
            break;

            case 4: 
            SetIndexMenu(tmpIndexMenu);
            navigation("commercial_sales");
            break;

            case 5: 
            SetIndexMenu(tmpIndexMenu);
            navigation("customers");
            break;

            case 6: 
            SetIndexMenu(tmpIndexMenu);
            navigation("week_discounts");
            break;
        }
    }

    return(
        <MenuLeftContainer>
        <div style={{display:"flex", marginTop:"10px", alignContent:"center", marginBottom:"30px"}}>
            {/*}
            <svg width="25px" height="25px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#ffffffcc" fillRule="evenodd" d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"/>
            </svg>*/}
            <p style={{margin:"0px 0px 0px 5px", fontSize:"1.1rem", color:"var(--color-font-light)"}}>Colombia Living</p>            
        </div>
        <nav>
            {/*
            <MenuLeftButton title={"Home"} icon={"Home"} isSelected={(indexMenu === 0) && true} 
            onClick={()=>{OnClickButtonMenu(0)}}/>
            */}
            {authContext.user.rol_idrol <= 2 && <MenuLeftButton title={"Usuarios"} icon={"User"} isSelected={indexMenu === 1 && true} onClick={()=>{OnClickButtonMenu(1)}}/>}
            {authContext.user.rol_idrol <= 2 && <MenuLeftButton title={"Properties"} icon={"Properties"} isSelected={indexMenu === 2 && true} onClick={()=>{OnClickButtonMenu(2)}}/>}

            {/*
                <ul style={{margin:"5px", paddingLeft:"15px"}}>
                    <li style={{margin:"0px"}}>Por semana</li>
                    <li style={{margin:"0px"}}>Por estado</li>
                    <li style={{margin:"0px"}}>Cambiar estado</li>
                </ul>*/}

            <MenuLeftButton title={"Ventas Properties"} icon={"VProperties"} isSelected={indexMenu === 3 && true} onClick={()=>{OnClickButtonMenu(3)}}/>

                {/*
                <svg style={{margin:"3px 10px 0px 0px"}} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 22H2" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M21 22V14.5C21 13.6716 20.3284 13 19.5 13H16.5C15.6716 13 15 13.6716 15 14.5V22" stroke="#1C274C" strokeWidth="1.5"/>
                    <path d="M15 22V5C15 3.58579 15 2.87868 14.5607 2.43934C14.1213 2 13.4142 2 12 2C10.5858 2 9.87868 2 9.43934 2.43934C9 2.87868 9 3.58579 9 5V22" stroke="#1C274C" strokeWidth="1.5"/>
                    <path d="M9 22V9.5C9 8.67157 8.32843 8 7.5 8H4.5C3.67157 8 3 8.67157 3 9.5V22" stroke="#1C274C" strokeWidth="1.5"/>
                </svg>

                </ul>
                */}

            {authContext.user.rol_idrol <= 2 && <MenuLeftButton title={"Ventas Comerciales"} icon={"VComercial"} isSelected={indexMenu === 4 && true} onClick={()=>{OnClickButtonMenu(4)}}/>}

                {/* Estado Cotizada, Reservada, Vendida*/}
                {/*
                <ul style={{margin:"5px", paddingLeft:"15px"}}>                    
                    <li style={{margin:"0px"}}>Totales x estado</li>
                    <li style={{margin:"0px"}}>Clientes</li>
                </ul>
                */}

            {authContext.user.rol_idrol <= 2 && <MenuLeftButton title={"Clientes"} icon={"Customers"} isSelected={indexMenu === 5 && true} onClick={()=>{OnClickButtonMenu(5)}}/>}
                {/*
                <svg style={{margin:"3px 10px 0px 0px"}} width="20px" height="20px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.11413 8.35688C4.75894 8.56999 4.64377 9.03069 4.85688 9.38587C5.06999 9.74106 5.53069 9.85623 5.88587 9.64312L5.11413 8.35688ZM10.5 6L10.95 5.4C10.7061 5.21704 10.3756 5.19999 10.1141 5.35688L10.5 6ZM14.5 9L14.05 9.6C14.3236 9.80522 14.7014 9.79932 14.9685 9.58565L14.5 9ZM19.9685 5.58565C20.292 5.32689 20.3444 4.85493 20.0857 4.53148C19.8269 4.20803 19.3549 4.15559 19.0315 4.41435L19.9685 5.58565ZM17.75 19C17.75 19.4142 18.0858 19.75 18.5 19.75C18.9142 19.75 19.25 19.4142 19.25 19H17.75ZM19.25 11C19.25 10.5858 18.9142 10.25 18.5 10.25C18.0858 10.25 17.75 10.5858 17.75 11H19.25ZM9.75 19C9.75 19.4142 10.0858 19.75 10.5 19.75C10.9142 19.75 11.25 19.4142 11.25 19H9.75ZM11.25 11C11.25 10.5858 10.9142 10.25 10.5 10.25C10.0858 10.25 9.75 10.5858 9.75 11H11.25ZM13.75 19C13.75 19.4142 14.0858 19.75 14.5 19.75C14.9142 19.75 15.25 19.4142 15.25 19H13.75ZM15.25 14C15.25 13.5858 14.9142 13.25 14.5 13.25C14.0858 13.25 13.75 13.5858 13.75 14H15.25ZM5.75 19C5.75 19.4142 6.08579 19.75 6.5 19.75C6.91421 19.75 7.25 19.4142 7.25 19H5.75ZM7.25 14C7.25 13.5858 6.91421 13.25 6.5 13.25C6.08579 13.25 5.75 13.5858 5.75 14H7.25ZM5.88587 9.64312L10.8859 6.64312L10.1141 5.35688L5.11413 8.35688L5.88587 9.64312ZM10.05 6.6L14.05 9.6L14.95 8.4L10.95 5.4L10.05 6.6ZM14.9685 9.58565L19.9685 5.58565L19.0315 4.41435L14.0315 8.41435L14.9685 9.58565ZM19.25 19V11H17.75V19H19.25ZM11.25 19V11H9.75V19H11.25ZM15.25 19V14H13.75V19H15.25ZM7.25 19V14H5.75V19H7.25Z" fill="#1C274C"/>
                </svg>*/}

                {/*
                <ul style={{margin:"5px", paddingLeft:"15px"}}>                    
                    <li style={{margin:"0px"}}>Properties con estado y vendedor</li>
                    <li style={{margin:"0px"}}>Asignar pago</li>
                </ul>
                */}

            {authContext.user.rol_idrol <= 2 && <MenuLeftButton title={"% Descuento Semana"} icon={"WeekDiscount"} isSelected={indexMenu === 6 && true} onClick={()=>{OnClickButtonMenu(6)}}/>}

            {/*authContext.user.rol_idrol <= 2 && <MenuLeftButton title={"% Separación Piso"} icon={"FloorSeparation"} isSelected={indexMenu === 5 && true} onClick={()=>{OnClickButtonMenu(5)}}/>*/}
        </nav>
        </MenuLeftContainer>
    );
}