export const CommercialSalesService = {
    
    GetAllCommercialSales(token, page, perPage, orderColumn, orderType, callback) {        
        fetch('https://api.colombialivinghotels.com/commercialsales_all', {
        //fetch('/commercialsales_all', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({token:token, page:page, per_page:perPage, order_by:orderColumn, order_type:orderType})
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("GetAllPropertySales Message:",data.Message);
            callback(parseInt(data.Status) ,data.Message, data.CommercialSales, data.TotalPages, data.Token);
        });
    },
}