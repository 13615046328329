export const PropertySalesService = {
    
    GetAllPropertySales(token, page, perPage, orderColumn, orderType, callback) {        
        fetch('https://api.colombialivinghotels.com/propertysales_all', {
        //fetch('/propertysales_all', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({token:token, page:page, per_page:perPage, order_by:orderColumn, order_type:orderType})
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("GetAllPropertySales Message:",data.Message);
            callback(parseInt(data.Status) ,data.Message, data.PropertySales, data.TotalPages, data.Token);
        });
    },

    EditPropertySaleService(token, item, callback) {               
        fetch('https://api.colombialivinghotels.com/propertysale_edit', {
        //fetch('/propertysale_edit', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({
                token:token,
                compra_idcompra:item.compra_idcompra.current,
                weeks_idweek:item.weeks_idweek.current,
                floor_percentage_idfloor_percentage:item.floor_percentage_idfloor_percentage.current,
                apartamento_idapartamento:item.apartamento_idapartamento.current,
                estado:item.estado.current.value,
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("EditPropertySaleService Message:",data.Message);            
            callback(parseInt(data.Status) ,data.Message, data.Token);
        });
    },

    GetCommercialPropertySales(token, page, perPage, orderColumn, orderType, idcommercialSales ,callback) {        
        fetch('https://api.colombialivinghotels.com/commercialpropertysales_all', {
        //fetch('/commercialpropertysales_all', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({token:token, page:page, per_page:perPage, 
                order_by:orderColumn, order_type:orderType, idcommercialSales:idcommercialSales})
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("GetAllPropertySales Message:",data.Message);
            callback(parseInt(data.Status) ,data.Message, data.PropertySales, data.TotalPages, data.Token);
        });
    },
}