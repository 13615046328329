import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Contexts
import { AuthContext } from "../contexts/AuthContext";

//Assets
import LogoLiving from '../assets/img/logo_color.png'

//Components
import AlertModal from "../data_display/AlertModal";

export default function LoginPage () {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [showAlert, setShowAlert] = useState(false);
    const alertMessage = useRef("");

    const inputEmail = useRef();
    const inputClave = useRef();

    useEffect(()=> {
        let token = sessionStorage.getItem('token');
        if(token != null)
            authContext.signin({token:token, 
                email: null, 
                clave: null,
                rememberme: 0
            }, onSuccessLogin);

        return ()=>{
            token = null;
        }
    },[]);
    

    function onClickLogin(e)
    {
        e.preventDefault();
        //setFormUser();
        authContext.signin({token:"", 
            email:inputEmail.current.value, 
            clave: inputClave.current.value,
            rememberme: 0
        }, onSuccessLogin);
    }

    function onSuccessLogin(tmpStatus, tmpMessage, tmpUser)
    {        
        switch(tmpStatus) {
            case 1: //Success                
                alertMessage.current = `Bienvenido ${tmpUser.nombres}!`;
                setShowAlert(true);
                break;
            default: //Validation error
                alertMessage.current = ""+tmpMessage;
                setShowAlert(true);
                break;
        }      
    }
 
    return (
        <>
            <main style={{width:"70%", maxWidth:"600px", 
            backgroundColor:"var(--color-dark-5)", borderRadius:"10px",
            margin:"20px auto", display:"flex", flexFlow:"column", padding:"20px",
            alignItems:"stretch", justifyContent:"center"}}>
                <img style={{ width:"40%", margin:"auto" }} src={LogoLiving} />
                <input ref={inputEmail} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="email" placeholder="Ingrese el email" />  
                <input ref={inputClave} style={{ flex:"1 0 auto", margin:"10px" }} 
                type="password" placeholder="Ingrese la clave" />            
                <button className="dark" style={{flex:"0.5 0 auto", margin:"10px 20px 10px 20px" }} 
                onClick={(e)=> onClickLogin(e)}>Login</button>
            </main>
            {showAlert && <AlertModal message={alertMessage.current} onAccept={()=>{setShowAlert(false); navigate('/home')}} />}
        </>
    );
}