import React, { useRef, useState, useContext } from "react";

//context
import { AuthContext } from "../contexts/AuthContext";

//data display
import AlertModal from "../data_display/AlertModal";

//services
import { PropertiesService } from "../services/PropertiesService";

export default function AlertCreateProperty ({onSave, onCancel}) {
    const authContext = useContext(AuthContext); 

    const formValues = {
        idweek:useRef(),
        idfloor:useRef(),
        idapartamento:useRef(),
        idtipo:useRef(),
        estado:useRef(),
    };

    const [showAlert, setShowAlert] = useState(false);
    const alertMessage = useRef("");

    function onClickSave(e) {
        e.preventDefault();
        let isValidForm = true;
    
        if(isValidForm)
        {
            PropertiesService.SetPropertyService(authContext.token, formValues, CallbackUserSave)
        }else {
            setShowAlert(true);
        }
    };

    function CallbackUserSave(tmpStatus, tmpMessage, tmpToken) {
        switch(tmpStatus) {
            case 1: //Success                                
                authContext.token = tmpToken;
                onSave();
                break;
            default: //Validation error
                alertMessage.current = ""+tmpMessage;
                setShowAlert(true);
                break;
        }   
    }

    function getWeeks() {
        let tmpWeeks = [];
        for(let i=0; i<52; i++)
        {
            let index = i+1;
            tmpWeeks[i] = <option value={index} key={"w"+i} >{index}</option>
        }
        return tmpWeeks;
    }
    function getFloors() {
        let tmpWeeks = [];
        for(let i=6; i<23; i++)
        {
            let index = i+1;
            tmpWeeks[i] = <option value={index} key={"f"+i} >{index}</option>
        }
        return tmpWeeks;
    }

    function getAptos() {
        let tmpWeeks = [];
        for(let i=0; i<16; i++)
        {
            let index = i+1;
            tmpWeeks[i] = <option value={index} key={"a"+i} >{index}</option>
        }
        return tmpWeeks;
    }

    return(
        <>        
        {showAlert && <AlertModal message={alertMessage.current} onAccept={()=>{setShowAlert(false);}} />}
        <div style={{width:"100vw",height:"100vh", position:"absolute", top:"0px", 
        right:"0px", zIndex:"2", backgroundColor:"var(--color-dark-50)", display:"flex"}}>            
            <div style={{width:"80%", maxWidth:"500px", margin:"auto", backgroundColor:"var(--color-light-100)",
                flexFlow:"column", padding:"20px", display:"flex",
                alignItems:"stretch", justifyContent:"center"}}>
                <p className="title" style={{textAlign:"center"}}>Bloquear Property</p>
                <label style={{ flex:"1 0 auto", margin:"0px 10px 0px 10px"}} >Semana</label>
                <select ref={formValues.idweek}  placeholder="Rol" style={{ flex:"1 0 auto", margin:"10px" }}>
                    {getWeeks()}
                </select>
                <label style={{ flex:"1 0 auto", margin:"0px 10px 0px 10px"}} >Piso</label>
                <select ref={formValues.idfloor}  placeholder="Rol" style={{ flex:"1 0 auto", margin:"10px" }}>
                    {getFloors()}
                </select>
                <label style={{ flex:"1 0 auto", margin:"0px 10px 0px 10px"}} >Apartamento</label>
                <select ref={formValues.idapartamento}  placeholder="Rol" style={{ flex:"1 0 auto", margin:"10px" }}>
                    {getAptos()}
                </select>
                <label style={{ flex:"1 0 auto", margin:"0px 10px 0px 10px"}} >Tipo</label>
                <select ref={formValues.idtipo}  placeholder="Rol" style={{ flex:"1 0 auto", margin:"10px" }}>
                    <option value="1" defaultValue>Suit Classic N</option>
                    <option value="2">Suit Classic S</option>
                    <option value="3">Suit Delux E</option>
                    <option value="4">Suit Delux W</option>
                    <option value="5">Embassy E</option>
                    <option value="6" >Embassy W</option>
                    <option value="7">Collection E</option>
                    <option value="8">Collection W</option>
                </select>
                {/* 0=disponible,1=reservado,2=bloqueado,3=vendido, 4=cancelada */}    
                <label style={{ flex:"1 0 auto", margin:"0px 10px 0px 10px"}} >Estado</label> 
                <select ref={formValues.estado}  placeholder="Rol" style={{ flex:"1 0 auto", margin:"10px" }}>
                    <option value="0">Disponible</option>
                    <option value="2">Bloqueda</option>
                </select>
                
                <div style={{width:"100%", display:"flex"}}>
                    <button className="light" style={{flex:"0.5 0 auto", margin:"10px 20px 10px 20px" }} 
                    onClick={()=> onCancel()}>Cancelar</button>
                    <button className="dark" style={{flex:"0.5 0 auto", margin:"10px 20px 10px 20px" }} 
                    onClick={(e)=> onClickSave(e)}>Guardar</button>
                </div>       
            </div>
        </div>
        </>
    );
}