export const PropertiesService = {
    
    GetAllPropertiesService(token, page, perPage, orderColumn, orderType, callback) {        
        fetch('https://api.colombialivinghotels.com/properties_all', {
        //fetch('/properties_all', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({token:token, page:page, per_page:perPage, order_by:orderColumn, order_type:orderType})
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("GetAllUsersService Message:",data.Message);
            callback(parseInt(data.Status) ,data.Message, data.Properties, data.TotalPages, data.Token);
        });
    },

    SetPropertyService(token, property, callback) {               
        fetch('https://api.colombialivinghotels.com/properties_add', {
        //fetch('/properties_add', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({
                token:token,
                idweek: property.idweek.current.value,
                idfloor: property.idfloor.current.value,
                idapartamento: property.idapartamento.current.value,
                idtipo: property.idtipo.current.value,
                estado: property.estado.current.value,
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("GetAllUsersService Message:",data.Message);            
            callback(parseInt(data.Status) ,data.Message, data.Token);
        });
    },

    EditPropertyService(token, property, callback) {               
        fetch('https://api.colombialivinghotels.com/properties_edit', {
        //fetch('/properties_edit', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({
                token:token,
                idweek: property.idweek.current.value,
                idfloor: property.idfloor.current.value,
                idapartamento: property.idapartamento.current.value,
                idtipo: property.idtipo.current.value,
                estado: property.estado.current.value,
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("EditUsersService Message:",data.Message);            
            callback(parseInt(data.Status) ,data.Message, data.Token);
        });
    }
}