export const UserService = {
    
    GetAllUsersService(token, page, perPage, orderColumn, orderType, callback) {        
        fetch('https://api.colombialivinghotels.com/user_all', {
        //fetch('/user_all', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({token:token, page:page, per_page:perPage, order_by:orderColumn, order_type:orderType})
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("GetAllUsersService Message:",data.Message);
            callback(parseInt(data.Status) ,data.Message, data.Users, data.TotalPages, data.Token);
        });
    },

    SetUsersService(token, user, callback) {               
        fetch('https://api.colombialivinghotels.com/user_add', {
        //fetch('/user_add', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({
                token:token,
                nombres:user.nombres.current.value,
                apellidos:user.apellidos.current.value,
                identificacion:user.identificacion.current.value,
                telefono:user.telefono.current.value,
                email:user.email.current.value,
                clave:user.clave.current.value,
                rol_idrol:user.rol.current.value,
                ciudad_idciudad:user.ciudad.current.value,
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("GetAllUsersService Message:",data.Message);            
            callback(parseInt(data.Status) ,data.Message, data.Token);
        });
    },

    EditUsersService(token, user, callback) {               
        fetch('https://api.colombialivinghotels.com/user_edit', {
        //fetch('/user_edit', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                //'Authorization': 'Bearer '+userData.token
            },
            body: JSON.stringify({
                token:token,
                idusuario_backoffice:user.idusuario.current,
                nombres:user.nombres.current.value,
                apellidos:user.apellidos.current.value,
                identificacion:user.identificacion.current.value,
                telefono:user.telefono.current.value,
                email:user.email.current.value,
                clave:user.clave.current.value,
                rol_idrol:user.rol_idrol.current.value,
                ciudad_idciudad:user.ciudad.current.value,
                enabled:user.enabled.current.value  === "true" ? 1:0,
            })
        })
        .then(response => response.json())
        .catch(error => {
            console.error('Error:', error);
            alert("Error de conexión.");
        })
        .then(data => {
            console.log("EditUsersService Message:",data.Message);            
            callback(parseInt(data.Status) ,data.Message, data.Token);
        });
    }
}