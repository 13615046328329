import React from "react";

export default function MenuLeftContainer ({children}) 
{    
    return (
        <div style={{minWidth: "180px", boxShadow: "0px 0px 5px 0px var(--color-light-shadow)",
        height: "100vh", padding:"10px", maxWidth:"180px", backgroundColor:"var(--color-blue-1)",
        position: "fixed"}}>
            {children}
        </div>
    );
}