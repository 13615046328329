import './App.css';
import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";

//context
import { AuthContext } from "./contexts/AuthContext";

//layouts
import AppContainer from './layouts/AppContainer';

//navigation
import MenuLeft from './navigation/MenuLeft';

//pages
import LoginView from './pages/LoginPage';
import UserPage from './pages/UserPage';
import PropertySalesPage from './pages/PropertySalesPage';
import CommercialSalesPage from './pages/CommercialSalesPage';
import CustomersPage from './pages/CustomersPage';
import PropertiesPage from './pages/PropertiesPage';
import WeekDiscountPage from './pages/WeekDiscountPage';

function App() {
  const auth = useContext(AuthContext);

  if(auth.user === null)
  { 
    return(
    <Routes>
        <Route>          
          <Route path="/" element={<LoginView />} />    
          <Route path="login" element={<LoginView />} />       
          <Route path="*" element={<LoginView />} />    
        </Route>
      </Routes>
    );
  }else {
    //filtrar las opciones segun el rol
    if(auth.user.rol_idrol == 1) {
      return (    
        <AppContainer>       
          <MenuLeft /> 
          <Routes>        
            <Route>                      
              <Route path="/" element={<PropertySalesPage />} />                         
              <Route path="*" element={<PropertySalesPage />} />
              <Route path="user" element={<UserPage />} />  
              <Route path="properties" element={<PropertiesPage />} />  
              <Route path="property_sales" element={<PropertySalesPage />} />  
              <Route path="commercial_sales" element={<CommercialSalesPage />} />  
              <Route path="customers" element={<CustomersPage />} />  
              <Route path="week_discounts" element={<WeekDiscountPage />} />              
            </Route>
          </Routes>
        </AppContainer>
      );
    }else if(auth.user.rol_idrol == 2) {
      return (    
        <AppContainer>       
          <MenuLeft /> 
          <Routes>        
            <Route>                      
              <Route path="/" element={<PropertySalesPage />} />                         
              <Route path="*" element={<PropertySalesPage />} />
              <Route path="user" element={<UserPage />} />  
              <Route path="properties" element={<PropertiesPage />} />  
              <Route path="property_sales" element={<PropertySalesPage />} />  
              <Route path="commercial_sales" element={<CommercialSalesPage />} />  
              <Route path="customers" element={<CustomersPage />} />              
            </Route>
          </Routes>
        </AppContainer>
      );
    }else if(auth.user.rol_idrol == 3) {
      return (    
        <AppContainer>       
          <MenuLeft /> 
          <Routes>        
            <Route>                      
              <Route path="/" element={<PropertySalesPage />} />                         
              <Route path="*" element={<PropertySalesPage />} />
              <Route path="properties" element={<PropertiesPage />} />  
              <Route path="commercial_sales" element={<CommercialSalesPage />} />  
            </Route>
          </Routes>
        </AppContainer>
      );
    }
  }
}

export default App;
