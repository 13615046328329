import React from "react";

//css
import Styles from "./MenuleftButton.module.css";

export default function MenuLeftButton ({title, icon, isSelected, onClick}) {
    let iconSVG = '';

    switch (icon){
        case "Home": iconSVG = <svg style={{margin:"3px 10px 0px 0px"}} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.36407 12.9579C1.98463 10.3208 1.79491 9.00229 2.33537 7.87495C2.87583 6.7476 4.02619 6.06234 6.32691 4.69181L7.71175 3.86687C9.80104 2.62229 10.8457 2 12 2C13.1543 2 14.199 2.62229 16.2882 3.86687L17.6731 4.69181C19.9738 6.06234 21.1242 6.7476 21.6646 7.87495C22.2051 9.00229 22.0154 10.3208 21.6359 12.9579L21.3572 14.8952C20.8697 18.2827 20.626 19.9764 19.451 20.9882C18.2759 22 16.5526 22 13.1061 22H10.8939C7.44737 22 5.72409 22 4.54903 20.9882C3.37396 19.9764 3.13025 18.2827 2.64284 14.8952L2.36407 12.9579Z" stroke="#ffffffcc" strokeWidth="1.5"/>
                    <path d="M15 18H9" stroke="#ffffffcc" strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>;
                    break;

        case "User": iconSVG = <svg style={{margin:"3px 10px 0px 0px"}} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="9" r="3" stroke="#ffffffcc" strokeWidth="1.5"/>
                        <path d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20" stroke="#ffffffcc" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#ffffffcc" strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>;
                    break;
        
        case "Properties": iconSVG = <svg style={{margin:"3px 10px 0px 0px"}} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 22L2 22" stroke="#ffffffcc" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M17 22V6C17 4.11438 17 3.17157 16.4142 2.58579C15.8284 2 14.8856 2 13 2H11C9.11438 2 8.17157 2 7.58579 2.58579C7 3.17157 7 4.11438 7 6V22" stroke="#ffffffcc" strokeWidth="1.5"/>
                        <path d="M21 22V11.5C21 10.0955 21 9.39331 20.6629 8.88886C20.517 8.67048 20.3295 8.48298 20.1111 8.33706C19.6067 8 18.9045 8 17.5 8" stroke="#ffffffcc" strokeWidth="1.5"/>
                        <path d="M3 22V11.5C3 10.0955 3 9.39331 3.33706 8.88886C3.48298 8.67048 3.67048 8.48298 3.88886 8.33706C4.39331 8 5.09554 8 6.5 8" stroke="#ffffffcc" strokeWidth="1.5"/>
                        <path d="M12 22V19" stroke="#ffffffcc" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M10 5H14" stroke="#ffffffcc" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M10 8H14" stroke="#ffffffcc" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M10 11H14" stroke="#ffffffcc" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M10 14H14" stroke="#ffffffcc" strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>;
                    break;
        
        case "VProperties": iconSVG = <svg style={{margin:"3px 10px 0px 0px"}} width="20px" height="20px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.11413 8.35688C4.75894 8.56999 4.64377 9.03069 4.85688 9.38587C5.06999 9.74106 5.53069 9.85623 5.88587 9.64312L5.11413 8.35688ZM10.5 6L10.95 5.4C10.7061 5.21704 10.3756 5.19999 10.1141 5.35688L10.5 6ZM14.5 9L14.05 9.6C14.3236 9.80522 14.7014 9.79932 14.9685 9.58565L14.5 9ZM19.9685 5.58565C20.292 5.32689 20.3444 4.85493 20.0857 4.53148C19.8269 4.20803 19.3549 4.15559 19.0315 4.41435L19.9685 5.58565ZM17.75 19C17.75 19.4142 18.0858 19.75 18.5 19.75C18.9142 19.75 19.25 19.4142 19.25 19H17.75ZM19.25 11C19.25 10.5858 18.9142 10.25 18.5 10.25C18.0858 10.25 17.75 10.5858 17.75 11H19.25ZM9.75 19C9.75 19.4142 10.0858 19.75 10.5 19.75C10.9142 19.75 11.25 19.4142 11.25 19H9.75ZM11.25 11C11.25 10.5858 10.9142 10.25 10.5 10.25C10.0858 10.25 9.75 10.5858 9.75 11H11.25ZM13.75 19C13.75 19.4142 14.0858 19.75 14.5 19.75C14.9142 19.75 15.25 19.4142 15.25 19H13.75ZM15.25 14C15.25 13.5858 14.9142 13.25 14.5 13.25C14.0858 13.25 13.75 13.5858 13.75 14H15.25ZM5.75 19C5.75 19.4142 6.08579 19.75 6.5 19.75C6.91421 19.75 7.25 19.4142 7.25 19H5.75ZM7.25 14C7.25 13.5858 6.91421 13.25 6.5 13.25C6.08579 13.25 5.75 13.5858 5.75 14H7.25ZM5.88587 9.64312L10.8859 6.64312L10.1141 5.35688L5.11413 8.35688L5.88587 9.64312ZM10.05 6.6L14.05 9.6L14.95 8.4L10.95 5.4L10.05 6.6ZM14.9685 9.58565L19.9685 5.58565L19.0315 4.41435L14.0315 8.41435L14.9685 9.58565ZM19.25 19V11H17.75V19H19.25ZM11.25 19V11H9.75V19H11.25ZM15.25 19V14H13.75V19H15.25ZM7.25 19V14H5.75V19H7.25Z" fill="#ffffffcc"/>
                    </svg>;
                    break;

        case "VComercial": iconSVG = <svg style={{margin:"3px 10px 0px 0px"}} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 16H17.8C18.9201 16 19.4802 16 19.908 15.782C20.2843 15.5903 20.5903 15.2843 20.782 14.908C21 14.4802 21 13.9201 21 12.8V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H9.2C8.07989 3 7.51984 3 7.09202 3.21799C6.71569 3.40973 6.40973 3.71569 6.21799 4.09202C6.01338 4.49359 6.00082 5.01165 6.00005 6M18 6L13 11L10 8M18 6V9M18 6H15M9 12C9 13.1046 8.10457 14 7 14C5.89543 14 5 13.1046 5 12C5 10.8954 5.89543 10 7 10C8.10457 10 9 10.8954 9 12ZM7 17C7.92997 17 8.39496 17 8.77646 17.1022C9.81173 17.3796 10.6204 18.1883 10.8978 19.2235C11 19.605 11 20.07 11 21H3C3 20.07 3 19.605 3.10222 19.2235C3.37962 18.1883 4.18827 17.3796 5.22354 17.1022C5.60504 17 6.07003 17 7 17Z" stroke="#ffffffcc" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    break;
                    
        case "Customers": iconSVG = <svg style={{margin:"3px 10px 0px 0px"}} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.36264 3.53846C7.60261 3.53846 6.17582 4.96525 6.17582 6.72528C6.17582 8.4853 7.60261 9.91209 9.36264 9.91209C11.1227 9.91209 12.5494 8.4853 12.5494 6.72528C12.5494 4.96525 11.1227 3.53846 9.36264 3.53846ZM4.63736 6.72528C4.63736 4.11558 6.75294 2 9.36264 2C11.9723 2 14.0879 4.11558 14.0879 6.72528C14.0879 9.33497 11.9723 11.4506 9.36264 11.4506C6.75294 11.4506 4.63736 9.33497 4.63736 6.72528Z" fill="#ffffffcc"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.41153 15.4066C5.27249 15.4066 3.53846 17.1406 3.53846 19.2797C3.53846 19.3861 3.55682 19.4596 3.57526 19.5013C3.59011 19.5348 3.60106 19.5412 3.60591 19.544C4.13353 19.8541 5.65133 20.4615 9.36264 20.4615C13.0739 20.4615 14.5913 19.8543 15.1189 19.5443C15.1238 19.5414 15.1352 19.5348 15.15 19.5013C15.1685 19.4596 15.1868 19.3861 15.1868 19.2797C15.1868 17.1406 13.4528 15.4066 11.3137 15.4066H7.41153ZM2 19.2797C2 16.291 4.42282 13.8681 7.41153 13.8681H11.3137C14.3024 13.8681 16.7253 16.291 16.7253 19.2797C16.7253 19.7944 16.5513 20.4869 15.8984 20.8706C15.0381 21.3763 13.2089 22 9.36264 22C5.51639 22 3.68722 21.3763 2.82683 20.8706C2.17398 20.4869 2 19.7944 2 19.2797Z" fill="#ffffffcc"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.5431 3.88721C15.654 3.47709 16.0763 3.23448 16.4864 3.34532C18.1832 3.80392 19.3626 5.47949 19.3626 7.38461C19.3626 9.39763 17.9829 11.2126 16.0478 11.4451C15.626 11.4957 15.243 11.1949 15.1923 10.7731C15.1416 10.3513 15.4425 9.96824 15.8643 9.91758C16.8962 9.79362 17.8242 8.75741 17.8242 7.38461C17.8242 6.08213 17.0256 5.0847 16.085 4.8305C15.6749 4.71966 15.4323 4.29733 15.5431 3.88721Z" fill="#ffffffcc"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.8384 14.4901C17.9197 14.0731 18.3237 13.801 18.7407 13.8824C20.6337 14.2516 22 15.91 22 17.8388V18.3735C22 18.8469 21.858 19.5212 21.2404 19.9159C20.8246 20.1817 20.1798 20.4649 19.1855 20.666C18.7691 20.7503 18.3633 20.481 18.279 20.0646C18.1948 19.6482 18.464 19.2424 18.8804 19.1581C19.7287 18.9865 20.1871 18.7633 20.4118 18.6197L20.4136 18.6179C20.4153 18.6158 20.4213 18.6077 20.4287 18.5896C20.4451 18.5498 20.4615 18.4784 20.4615 18.3735V17.8388C20.4615 16.6462 19.6167 15.6207 18.4462 15.3924C18.0292 15.311 17.7571 14.9071 17.8384 14.4901Z" fill="#ffffffcc"/>
                    </svg>
                    break; 

        case "WeekDiscount": iconSVG = <svg style={{margin:"3px 10px 0px 0px"}} width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-260.000000, -2959.000000)" fill="#ffffffcc">
                        <g transform="translate(56.000000, 160.000000)">
                        <path d="M207.977728,2804.02015 L207.977728,2804.02015 C207.977728,2803.44427 208.445082,2802.97689 209.020929,2802.97689 C209.596777,2802.97689 210.064131,2803.44427 210.064131,2804.02015 C210.064131,2804.59603 209.596777,2805.0634 209.020929,2805.0634 C208.445082,2805.0634 207.977728,2804.59603 207.977728,2804.02015 L207.977728,2804.02015 Z M215.274923,2816.17617 L206.239754,2807.14053 C206.141693,2807.04246 206.087446,2806.90997 206.087446,2806.77121 L206.087446,2802.12977 C206.087446,2801.55285 206.553757,2801.08547 207.130648,2801.08547 L211.771852,2801.08547 C211.910598,2801.08547 212.043084,2801.14076 212.141145,2801.23883 L221.176314,2810.27447 C221.583163,2810.68238 221.583163,2811.34276 221.176314,2811.74963 L216.75001,2816.17617 C216.343161,2816.58304 215.682815,2816.58304 215.274923,2816.17617 L215.274923,2816.17617 Z M219.933861,2806.08162 L219.933861,2806.08162 C219.76069,2805.9074 212.984052,2799.13145 213.158267,2799.30463 C212.962145,2799.10954 212.697172,2799 212.420723,2799 L206.087446,2799 C204.934709,2799 204,2799.93371 204,2801.08547 L204,2807.42012 C204,2807.69658 204.110579,2807.96157 204.305658,2808.1577 L214.537379,2818.38891 C215.35212,2819.2037 216.672813,2819.2037 217.487553,2818.38891 L217.487553,2818.38891 C217.673243,2818.20321 223.454666,2812.42253 223.388945,2812.48721 C224.203685,2811.67243 224.203685,2810.35167 223.388945,2809.53689 L219.933861,2806.08162 Z" fill="#ffffffcc"></path>
                        </g>
                        </g>
                        </g>
                    </svg>            
                    break;
        
        case "FloorSeparation": iconSVG = <svg style={{margin:"3px 10px 0px 0px"}} width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-140.000000, -7959.000000)" fill="#ffffffcc">
                        <g transform="translate(56.000000, 160.000000)">
                        <path d="M102,7817 L86,7817 L86,7801 L90,7801 L90,7807 L96,7807 L96,7813 L102,7813 L102,7817 Z M98,7811 L98,7805 L92,7805 L92,7799 L84,7799 L84,7819 L104,7819 L104,7811 L98,7811 Z" fill="#ffffffcc"></path>
                        </g>
                        </g>
                        </g>
                    </svg>       
                    break;
    }


    return(
        <div className={`${Styles.buttonLeft} ${(isSelected ? Styles.selected:Styles.buttonLeft)}`}>
            {iconSVG}

            <p onClick={onClick}>
                {title}
            </p>
            {/* resumende ventas de properties con filtro semana estadom,
            vendedor y clientes y estado de transaccion*/}
        </div>
    );
};